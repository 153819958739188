import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getRecheckExam = (params) => {
  return axiosClient.get("post-check/exam-schedule", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getRecheckDetail = (idExamProfile) => {
  return axiosClient.get("post-check/detail/" + idExamProfile, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const getRecheckExamNCC = (params) => {
  return axiosClient.get(
    `post-check/exam-schedule/${params.idExamSchedule}/ncc`,
    {
      params: params.values,
      headers: {
        Authorization: getToken(),
      },
    }
  );
};

const putRecheck = (data) => {
  return axiosClient.put("post-check/confirm/" + data?.id, data?.data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportRecheckAPI = (params) => {
  return axiosClientDownload.get(`excel/post-check`, {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

export {
  getRecheckExam,
  getRecheckDetail,
  getRecheckExamNCC,
  putRecheck,
  exportRecheckAPI,
};
