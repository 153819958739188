import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getTabs = (params) => {
  return axiosClient.get("tab", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getTabDetail = (idUser) => {
  return axiosClient.get("tab/" + idUser, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportNCOs = (params, options) => {
  return axiosClient.get("members/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportTabAPI = (params, options) => {
  return axiosClientDownload.get("excel/tab/tabs", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getTabs, exportNCOs, getTabDetail, exportTabAPI };
