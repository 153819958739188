import axios from "axios";
import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getMasterImportList = (params) => {
  return axiosClient.get("master-import", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};
const getDownloadTemplate = (url) => {
  return axiosClient.get(`master-import/${url}`, {
    params: {},
    responseType: "blob",
    headers: {
      Authorization: getToken(),
    },
  });
};

const postMasterImport = (url, data) => {
  return axiosClient.post("master-import/" + url, data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

export { getMasterImportList, postMasterImport, getDownloadTemplate };
