import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getNCCs = (params) => {
  return axiosClient.get("ncc", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCDetail = (idUser) => {
  return axiosClient.get("ncc/" + idUser, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCCourse = (params) => {
  return axiosClient.get(`ncc/${params?.idUser}/list-course`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCExam = (params) => {
  return axiosClient.get(`ncc/${params?.idUser}/list-exam-schedule`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCClub = (params) => {
  return axiosClient.get(`ndd`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportNCCs = (params, options) => {
  return axiosClient.get("members/export", {
    params,
    ...options,
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportPresenters = (params, options) => {
  return axiosClient.get("members/referral/export", {
    params,
    ...options,
    headers: {
      Authorization: getToken(),
    },
  });
};

const resetDataEncoding = (id) => {
  return axiosClient.delete(`ncc/clear-face-data/${id}`, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportMemberAPI = (params, options) => {
  return axiosClientDownload.get("excel/member/members", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportCourseMemberAPI = (params, options) => {
  return axiosClientDownload.get("excel/member/courses-of-member", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportExamMemberAPI = (params, options) => {
  return axiosClientDownload.get("excel/member/examinations-of-member", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportMemberTabAPI = (params, options) => {
  return axiosClientDownload.get("excel/tab/members-of-tab", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const resetPasswordMember = (id) => {
  return axiosClient.put(
    "ncc/reset-password/" + id,
    {},
    {
      headers: {
        Authorization: getToken(),
      },
    }
  );
};

export {
  getNCCs,
  getNCCDetail,
  exportNCCs,
  exportPresenters,
  getNCCCourse,
  getNCCExam,
  getNCClub,
  resetDataEncoding,
  exportMemberAPI,
  exportCourseMemberAPI,
  exportExamMemberAPI,
  exportMemberTabAPI,
  resetPasswordMember,
};
