import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getTabDetail, getTabs } from "./tabAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getTABsAsync = createAsyncThunk(
  "tabs",
  async (params, thunkAPI) => {
    try {
      const response = await getTabs(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTABDetailAsync = createAsyncThunk(
  "tabDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getTabDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearTABDetail: (state) => {
      state.detail = undefined;
    },
    clearTABs: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTABsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTABsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getTABsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getTABs",
          };
        }
      })
      .addCase(getTABDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTABDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getTABDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getTABDetail",
          };
        }
      });
  },
});

export const selectTABs = (state) => state.tab.list;
export const selectTABDetail = (state) => state.tab.detail;
export const tabsStatus = (state) => state.tab.status;
export const tabsError = (state) => state.tab.error;

export const { clearTABDetail, clearTABs, setIdleStatus } = tabSlice.actions;

export default tabSlice.reducer;
