const statuses = [
  {
    id: 0,
    key: "0",
    label: "New",
    color: "#00B0FF",
    actionLabel: "Tài khoản mới tạo",
    nextAction: [1, 2, 3],
  },
  {
    id: 1,
    key: "1",
    label: "Active",
    color: "#00C853",
    actionLabel: "Đang hoạt động",
    nextAction: [2, 3],
  },
  {
    id: 2,
    key: "2",
    label: "Suspend",
    color: "#FFAB00",
    actionLabel: "Tạm ngưng",
    nextAction: [1, 3],
  },
  {
    id: 3,
    key: "3",
    label: "Locked",
    color: "#FF3D00",
    actionLabel: "Đã khóa",
    nextAction: [],
  },
];
const findStatus = (status_id, field) => {
  let status = statuses.find((s) => s.id * 1 === status_id * 1);
  return status
    ? field && status?.[field]
      ? status[field]
      : status
    : undefined;
};

const filterStatus = ({ array_ids = [], currentStatus = undefined }) => {
  if (currentStatus) {
    let current = findStatus(currentStatus);
    if (current?.nextAction) {
      return current.nextAction.map((item) => findStatus(item));
    }
    return;
  }
  return array_ids.map((item) => findStatus(item));
};

const SYSTEM_ADMIN = {
  id: 4,
  key: "4",
  label: "System Admin",
  color: "#00B0FF",
  actionLabel: "System Admin",
};
const TAB = {
  id: 2,
  key: "2",
  label: " Tab",
  color: "#00C853",
  actionLabel: "Tab",
};
const SUPER_ADMIN = {
  id: 5,
  key: "5",
  label: "Super Admin",
  color: "#00B0FF",
  actionLabel: "Super Admin",
};
const ADMIN = {
  id: 3,
  key: "3",
  label: "Admin",
  color: "#00B0FF",
  actionLabel: "Admin",
};
const user_roles = [SYSTEM_ADMIN, SUPER_ADMIN, ADMIN];
const findUserRole = (role, field) => {
  let obj = user_roles.find((s) => s.id * 1 === role * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

export {
  statuses,
  findStatus,
  filterStatus,
  SYSTEM_ADMIN,
  TAB,
  SUPER_ADMIN,
  ADMIN,
  user_roles,
  findUserRole,

  // ADMIN,
  // user_type,
  // findUserType
};
