import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getUsers = (params) => {
  return axiosClient.get("user", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};
const getUserDetail = (idUser) => {
  return axiosClient.get("user/" + idUser, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const postUser = (data) => {
  return axiosClient.post("user", data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putUser = (data) => {
  return axiosClient.put("user/" + data.id, data.values, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putUserStatus = (memberCredentialId, data) => {
  return axiosClient.patch(
    `/member-credentials/${memberCredentialId}/status`,
    data,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putUserPassword = (data) => {
  return axiosClient.post("authentication/change-password", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPasswordForUser = (id, data) => {
  // return axiosClient.put("users-password/" + id, data, {
  //     headers: {
  //         Authorization: "Bearer " + getToken()
  //     }
  // })
};

const resetPasswordForUser = (id) => {
  return axiosClient.put(`user/${id}/reset-password`, null, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportUserAPI = (params, options) => {
  return axiosClientDownload.get("excel/user-system", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getUsers,
  getUserDetail,
  postUser,
  putUser,
  putUserStatus,
  putUserPassword,
  putPasswordForUser,
  resetPasswordForUser,
  exportUserAPI,
};
