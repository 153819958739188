import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { result } from "lodash-es";
import { getNCCs, getNCCDetail, getNCClub } from "./nccAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  ncclub: undefined,
};

export const getNCCsAsync = createAsyncThunk(
  "nccs",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCs(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCDetailAsync = createAsyncThunk(
  "nccDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getNCCDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCClubsAsync = createAsyncThunk(
  "ncclubs",
  async (params, thunkAPI) => {
    try {
      const response = await getNCClub(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nccSlice = createSlice({
  name: "ncc",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNCCDetail: (state) => {
      state.detail = undefined;
    },
    clearNCCs: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNCCsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNCCsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNCCs",
          };
        }
      })
      .addCase(getNCCDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNCCDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNCCDetail",
          };
        }
      })
      .addCase(getNCClubsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCClubsAsync.fulfilled, (state, action) => {
        state.ncclub = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNCClubsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getNCClub",
          };
        }
      });
  },
});

export const selectNCCs = (state) => state.ncc.list;
export const selectNCCDetail = (state) => state.ncc.detail;
export const selectNCClub = (state) => state.ncc.ncclub;
export const nccsStatus = (state) => state.ncc.status;
export const nccsError = (state) => state.ncc.error;

export const { clearNCCDetail, clearNCCs, setIdleStatus } = nccSlice.actions;

export default nccSlice.reducer;
