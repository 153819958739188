import "animate.css";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./App.less";
import Loading from "../src/assets/lottie-json/loading.json";
import Lottie from "lottie-react";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./containers/Login"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{ width: "300px" }}
              animationData={Loading}
              autoPlay={true}
              loop={true}
            />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={Login} />
            <Route path="/:locale" component={DefaultLayout} />
          </Switch>
        </BrowserRouter>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
