import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getNCCFastRegister = (params) => {
  return axiosClient.get("registry-final-exam/list-ncc", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getExamDateFastRegister = (params) => {
  return axiosClient.get("registry-final-exam/get-date", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

export { getNCCFastRegister, getExamDateFastRegister };
