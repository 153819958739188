import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { EXAM_DONE } from "common/Member";
import _ from "lodash";
import {
  getRecheckExam,
  getRecheckDetail,
  getRecheckExamNCC,
  putRecheck,
  exportRecheck,
  exportRecheckAPI,
} from "./recheckAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  nccList: undefined,
};

export const getRechecksAsync = createAsyncThunk(
  "rechecks",
  async (params, thunkAPI) => {
    try {
      const response = await getRecheckExam(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getRecheckDetailAsync = createAsyncThunk(
  "recheckDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getRecheckDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putRecheckAsync = createAsyncThunk(
  "putRecheck",
  async (data, thunkAPI) => {
    try {
      const response = await putRecheck(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCofRecheckAsync = createAsyncThunk(
  "nccRechecks",
  async (params, thunkAPI) => {
    try {
      const response = await getRecheckExamNCC(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const exportRecheckAsync = createAsyncThunk(
  "recheckExport",
  async (id, thunkAPI) => {
    try {
      const response = await exportRecheckAPI(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const recheckSlice = createSlice({
  name: "recheck",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearRecheckDetail: (state) => {
      state.detail = undefined;
    },
    clearRechecks: (state) => {
      state.list = undefined;
    },
    clearNCCRechecks: (state) => {
      state.nccList = undefined;
    },
    approveNCCRecheck: (state) => {
      let cloneDetail = _.cloneDeep(state.detail);
      let cloneNCCList = _.cloneDeep(state.nccList);
      let index = _.findIndex(cloneNCCList, ["id", cloneDetail.profile.id]);

      cloneDetail.profile.status = EXAM_DONE?.id;
      if (index !== -1) cloneNCCList[index].examProfile.status = EXAM_DONE?.id;

      state.detail = cloneDetail;
      state.nccList = cloneNCCList;
    },
    denyNCCRecheck: (state) => {
      let cloneDetail = _.cloneDeep(state.detail);
      let cloneNCCList = _.cloneDeep(state.nccList);
      let index = _.findIndex(cloneNCCList, ["id", cloneDetail.profile.id]);

      cloneDetail.profile.status = EXAM_DONE?.id;
      if (index !== -1) cloneNCCList[index].examProfile.status = EXAM_DONE?.id;

      state.detail = cloneDetail;
      state.nccList = cloneNCCList;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getRechecksAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRechecksAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getRechecksAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getRechecks",
          };
        }
      })
      .addCase(getRecheckDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecheckDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getRecheckDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getRecheckDetail",
          };
        }
      })

      .addCase(putRecheckAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putRecheckAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state.detail);
        clone.status = action.payload.status;
        state.detail = clone;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putRecheckAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật trạng thái khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "addRecheck",
          };
        }
      })
      .addCase(getNCCofRecheckAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCofRecheckAsync.fulfilled, (state, action) => {
        state.nccList = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getNCCofRecheckAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy danh sách hậu kiểm thất bại");
          state.error = {
            payload: action.payload,
            title: "exportRechecks",
          };
        }
      })
      .addCase(exportRecheckAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(exportRecheckAsync.fulfilled, (state, action) => {
        state.status = "done";
        state.error = undefined;
      })
      .addCase(exportRecheckAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Xuất danh sách hậu kiểm thất bại");
          state.error = {
            payload: action.payload,
            title: "exportRechecks",
          };
        }
      });
  },
});

export const selectRechecks = (state) => state.recheck.list;
export const selectRecheckDetail = (state) => state.recheck.detail;
export const selectNCCofRechecks = (state) => state.recheck.nccList;
export const rechecksStatus = (state) => state.recheck.status;
export const rechecksError = (state) => state.recheck.error;

export const {
  clearRecheckDetail,
  clearRechecks,
  setIdleStatus,
  clearNCCRechecks,
  approveNCCRecheck,
  denyNCCRecheck,
} = recheckSlice.actions;

export default recheckSlice.reducer;
