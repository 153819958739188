import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getQuestions = (params) => {
  return axiosClient.get("exam", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getQuestionDetail = (idQuestion) => {
  return axiosClient.get("exam/question/" + idQuestion, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const postQuestion = (data) => {
  return axiosClient.post("exam/question", data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putQuestion = (id, data) => {
  return axiosClient.put("exam/question/" + id, data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putStatusQuestion = (data) => {
  return axiosClient.post(
    "exam/question/" + data?.id + "/set-status",
    data?.data,
    {
      headers: {
        Authorization: getToken(),
      },
    }
  );
};

const getNCCofQuestions = (params) => {
  return axiosClient.get(`exam/list-ncc/${params?.idQuestion}`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCJoinQuestions = (idQuestion, params) => {
  return axiosClient.get(`tab/list-ncc-exam/${idQuestion}`, {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const pickNCCJoinQuestions = (params) => {
  return axiosClient.post(`tab/pick-exam`, params, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportQuestionAPI = (params, options) => {
  return axiosClientDownload.get("excel/questions", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getQuestions,
  getQuestionDetail,
  exportQuestionAPI,
  postQuestion,
  putQuestion,
  putStatusQuestion,
  getNCCofQuestions,
  getNCCJoinQuestions,
  pickNCCJoinQuestions,
};
