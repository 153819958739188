import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { result } from "lodash-es";
import {
  getCourses,
  getCourseDetail,
  postCourse,
  putStatusCourse,
  getNCCofCourses,
  getNCCJoinCourses,
} from "./courseAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  presenter: undefined,
  nccList: undefined,
  nccJoinList: undefined,
};

export const getCoursesAsync = createAsyncThunk(
  "courses",
  async (params, thunkAPI) => {
    try {
      const response = await getCourses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCourseDetailAsync = createAsyncThunk(
  "courseDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getCourseDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postCourseAsync = createAsyncThunk(
  "postCourse",
  async (data, thunkAPI) => {
    try {
      const response = await postCourse(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putStatusCourseAsync = createAsyncThunk(
  "putStatusCourse",
  async (data, thunkAPI) => {
    try {
      const response = await putStatusCourse(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCofCourseAsync = createAsyncThunk(
  "nccCourses",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCofCourses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCJoinCourseAsync = createAsyncThunk(
  "nccJoinCourses",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCJoinCourses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearCourseDetail: (state) => {
      state.detail = undefined;
    },
    clearCourses: (state) => {
      state.list = undefined;
    },
    clearNCCCourses: (state) => {
      state.nccList = undefined;
    },
    clearNCCJoinCourses: (state) => {
      state.nccJoinList = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCoursesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCoursesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCourses",
          };
        }
      })
      .addCase(getCourseDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCourseDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.course;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCourseDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCourseDetail",
          };
        }
      })
      .addCase(postCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postCourseAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(postCourseAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thêm mới khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "addCourse",
          };
        }
      })
      .addCase(putStatusCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putStatusCourseAsync.fulfilled, (state, action) => {
        state.detail = action.payload.course;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putStatusCourseAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật trạng thái khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "addCourse",
          };
        }
      })
      .addCase(getNCCofCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCofCourseAsync.fulfilled, (state, action) => {
        state.nccList = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getNCCofCourseAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy danh sách Thành Viên của khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "nccCourses",
          };
        }
      })
      .addCase(getNCCJoinCourseAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCJoinCourseAsync.fulfilled, (state, action) => {
        state.nccJoinList = action.payload?.members;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getNCCJoinCourseAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy danh sách Thành Viên thất bại");
          state.error = {
            payload: action.payload,
            title: "nccJoinCourses",
          };
        }
      });
  },
});

export const selectCourses = (state) => state.course.list;
export const selectCourseDetail = (state) => state.course.detail;
export const selectNCCofCourses = (state) => state.course.nccList;
export const selectNCCJoinCourses = (state) => state.course.nccJoinList;
export const coursesStatus = (state) => state.course.status;
export const coursesError = (state) => state.course.error;

export const {
  clearCourseDetail,
  clearCourses,
  setIdleStatus,
  clearNCCCourses,
} = courseSlice.actions;

export default courseSlice.reducer;
