import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getNCCFastRegister } from "./fastRegisterAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  presenter: undefined,
  nccList: undefined,
  nccJoinList: undefined,
};

export const getNCCFastRegisterAsync = createAsyncThunk(
  "nccOfFastRegister",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCFastRegister(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fastRegisterSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNCCFastRegister: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNCCFastRegisterAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCFastRegisterAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNCCFastRegisterAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getQuestions",
          };
        }
      });
  },
});

export const selectNCCFastRegister = (state) => state.fastRegister.list;
export const fastRegisterStatus = (state) => state.fastRegister.status;
export const fastRegisterError = (state) => state.fastRegister.error;

export const { clearNCCFastRegister } = fastRegisterSlice.actions;

export default fastRegisterSlice.reducer;
