import axiosClient from "../axiosClient";

const login = (data) => {
  return axiosClient.post("login", data);
};

const resetPassword = (params) => {
  return axiosClient.get("authentication/reset-password", { params });
};

const changePassword = (data) => {
  return axiosClient.post("change-password", data, {
    headers: {
      Authorization:
        localStorage?.hblELearning &&
        JSON.parse(localStorage.hblELearning)?.accessToken,
    },
  });
};

const sendcodeOTP = (data) => {
  return axiosClient.post("send-code", data, {
    headers: {
      Authorization:
        localStorage?.hblELearning &&
        JSON.parse(localStorage.hblELearning)?.accessToken,
    },
  });
};

const verifyOTP = (data) => {
  return axiosClient.post("verify-code", data, {
    headers: {
      Authorization:
        localStorage?.hblELearning &&
        JSON.parse(localStorage.hblELearning)?.accessToken,
    },
  });
};

const logout = () => {
  return axiosClient.post(
    "logout",
    {},
    {
      headers: {
        Authorization:
          localStorage?.hblELearning &&
          JSON.parse(localStorage.hblELearning)?.accessToken,
      },
    }
  );
};

const cancelRequestLogin = () => {
  return axiosClient.post(
    `authentication/cancel-login-other-devices?token=${
      localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken
    }`
  );
};

const loginByShortToken = (params) => {
  return axiosClient.get(`short-token?shortToken=${params?.shortToken}`);
};

export {
  login,
  resetPassword,
  changePassword,
  logout,
  cancelRequestLogin,
  sendcodeOTP,
  verifyOTP,
  loginByShortToken,
};
