const TabLevels = [
  {
    id: 9,
    key: "9",
    label: "Thành Viên Nhóm Phát Triển Toàn Cầu",
    color: "#00C853",
  },
  {
    id: 10,
    key: "10",
    label: "Thành Viên Nhóm Phát Triển Toàn Cầu 2500",
    color: "#00C853",
  },
  {
    id: 11,
    key: "11",
    label: "Thành Viên Nhóm Triệu phú",
    color: "#00C853",
  },
  {
    id: 12,
    key: "12",
    label: "Thành Viên Nhóm Triệu phú 7500",
    color: "#00C853",
  },
  {
    id: 13,
    key: "13",
    label: "Thành Viên Nhóm Chủ tịch",
    color: "#00C853",
  },
  {
    id: 14,
    key: "14",
    label: "Thành Viên Câu Lạc Bộ Chủ tịch",
    color: "#00C853",
  },
  {
    id: 15,
    key: "15",
    label: "Nhóm sáng lập",
    color: "#00C853",
  },
];

const findTabLevels = (level, field) => {
  let obj = TabLevels.find((s) => s.id * 1 === level * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const NCCLevels = [
  {
    id: 1,
    key: "1",
    label: "Khách hàng",
    color: "magenta",
  },
  {
    id: 2,
    key: "2",
    label: "Đại sứ sức khỏe",
    color: "blue",
  },
  {
    id: 3,
    key: "3",
    label: "Thành Viên",
    color: "green",
  },
];

const findNCCLevels = (level, field) => {
  let obj = NCCLevels.find((s) => s.id * 1 === level * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const NCOActivitiesStatus = [
  {
    id: 1,
    key: "1",
    label: "Chưa đăng nhập",
    color: "default",
  },
  {
    id: 2,
    key: "2",
    label: "Đã đăng nhập",
    color: "green",
  },
  {
    id: 3,
    key: "3",
    label: "Đã đăng xuất",
    color: "red",
  },
];

const findNCNCOActivitiesStatus = (level, field) => {
  let obj = NCOActivitiesStatus.find((s) => s.id * 1 === level * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const memberStatus = [
  {
    id: 0,
    key: "0",
    label: "Tài khoản mới tạo",
    color: "default",
  },
  {
    id: 1,
    key: "1",
    label: "Đang hoạt động",
    color: "green",
  },
  {
    id: 2,
    key: "2",
    label: "Tạm ngưng",
    color: "orange",
  },
  {
    id: 3,
    key: "3",
    label: "Đã khóa",
    color: "red",
  },
];

const findMemberStatus = (level, field) => {
  let obj = memberStatus.find((s) => s.id * 1 === level * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const healthInfos = [
  { name: "height", label: "Chiều cao (cm)" },
  { name: "weight", label: "Cân nặng (kg)" },
  { name: "fatPercentage", label: "Mỡ (%)" },
  { name: "visceralFat", label: "Mỡ nội tạng" },
  { name: "amountOfBones", label: "Lượng xương" },
  { name: "chcb", label: "CHCB (RMR)" },
  { name: "amountOfMuscle", label: "Lượng cơ bắp" },
  { name: "balanceIndex", label: "Chỉ số cân đối" },
  { name: "waterPercentage", label: "Nước (%)" },
];

const COURSE_NOT_YET = {
  id: 1,
  key: "1",
  label: "Chưa học",
  color: "default",
};

const COURSE_STUDY = {
  id: 2,
  key: "2",
  label: "Đang học",
  color: "geekblue",
};

const COURSE_DONE = {
  id: 3,
  key: "3",
  label: "Đã hoàn thành",
  color: "green",
};

const memberCourseStatus = [COURSE_NOT_YET, COURSE_STUDY, COURSE_DONE];

const findMemberCourseStatus = (status, field) => {
  let obj = memberCourseStatus.find((s) => s.id * 1 === status * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const EXAM_RESULT_PASS = {
  id: true,
  key: "true",
  label: "Đạt",
  color: "green",
};

const EXAM_RESULT_FAIL = {
  id: false,
  key: "false",
  label: "Không đạt",
  color: "red",
};

const memberExamResult = [EXAM_RESULT_PASS, EXAM_RESULT_FAIL];

const findMemberExamResult = (status, field) => {
  let obj = memberExamResult.find((s) => s.id * 1 === status * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const EXAM_CREATE = {
  id: 1,
  key: "1",
  label: "Chưa thi",
  color: "blue",
};

const EXAM_TEST = {
  id: 3,
  key: "3",
  label: "Tham gia thi",
  color: "geekblue",
};

const EXAM_RECHECK = {
  id: 4,
  key: "4",
  label: "Chờ hậu kiểm",
  color: "purple",
};

const EXAM_DONE = {
  id: 5,
  key: "5",
  label: "Đã hoàn thành",
  color: "green",
};

const EXAM_NOTYET = {
  id: 6,
  key: "6",
  label: "Bỏ thi",
  color: "red",
};

const EXAM_EXPIRED = {
  id: 7,
  key: "7",
  label: "Hết hạn",
  color: "default",
};

const memberExamStatus = [
  EXAM_CREATE,
  EXAM_TEST,
  EXAM_RECHECK,
  EXAM_DONE,
  EXAM_NOTYET,
  EXAM_EXPIRED,
];

const findMemberExamStatus = (status, field) => {
  let obj = memberExamStatus.find((s) => s.id * 1 === status * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const member_area = [
  {
    id: "South West",
    label: "South West",
  },
  {
    id: "South East",
    label: "South East",
  },
  {
    id: "NRR",
    label: "NRR",
  },
  {
    id: "Central",
    label: "Central",
  },
  {
    id: "SRR",
    label: "SRR",
  },
  {
    id: "Ha Noi",
    label: "Ha Noi",
  },
  {
    id: "HCM",
    label: "HCM",
  },
  {
    id: "Other",
    label: "Other",
  },
];

const findMemberArea = (status, field) => {
  let obj = member_area.find((s) => s.id * 1 === status * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const member_dashboard_result = [
  {
    id: "1",
    label: "Thi đậu",
  },
  {
    id: "2",
    label: "Thi rớt",
  },
  {
    id: "3",
    label: "Bỏ thi",
  },
];

const findMemberDashboardResult = (result, field) => {
  let obj = member_dashboard_result.find((s) => s.id * 1 === result * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

export {
  TabLevels,
  findTabLevels,
  NCCLevels,
  findNCCLevels,
  NCOActivitiesStatus,
  findNCNCOActivitiesStatus,
  memberStatus,
  findMemberStatus,
  healthInfos,
  COURSE_NOT_YET,
  COURSE_STUDY,
  COURSE_DONE,
  memberCourseStatus,
  findMemberCourseStatus,
  EXAM_CREATE,
  EXAM_TEST,
  EXAM_RECHECK,
  EXAM_DONE,
  EXAM_NOTYET,
  EXAM_EXPIRED,
  memberExamStatus,
  findMemberExamStatus,
  member_area,
  findMemberArea,
  EXAM_RESULT_PASS,
  EXAM_RESULT_FAIL,
  memberExamResult,
  findMemberExamResult,
  member_dashboard_result,
  findMemberDashboardResult,
};
