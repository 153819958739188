import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { changePassword, login, loginByShortToken } from "./authAPI";

const initialState = {
  token:
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined,
  info:
    (localStorage?.hblELearning && JSON.parse(localStorage.hblELearning)) ||
    undefined,
  listRoles:
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.listRoles) ||
    undefined,
  status: "idle",
  errorCode: undefined,
  firstLogin: undefined,
};

export const authAsync = createAsyncThunk(
  "login/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await login(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const shortTokenAsync = createAsyncThunk(
  "login/shortToken",
  async (data, { rejectWithValue }) => {
    try {
      const response = await loginByShortToken(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.info = undefined;
      state.errorCode = undefined;
      state.firstLogin = undefined;
      state.listRoles = undefined;
      localStorage.removeItem("hblELearning");
      return state;
    },
    updateAuth: (state, action) => {
      state.info = action.payload;
      localStorage.setItem("hblELearning", JSON.stringify(action.payload));
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.token = action.payload.token;
        state.info = action.payload;
        state.listRoles = action.payload?.listRoles;
        state.firstLogin =
          action.payload?.account?.status === 0 ||
          action.payload?.statusDescription?.status === 0
            ? true
            : false;
        state.errorCode = undefined;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.data?.errors?.[0]?.code === 100) {
          message.error("Tên tài khoản hoặc mật khẩu không đúng!");
        } else if (action?.payload?.data?.errors?.[0]?.code === 103) {
          message.error("Tài khoản không có quyền truy cập hệ thống!");
        } else {
          message.error("Đăng nhập thất bại");
        }
      })
      .addCase(shortTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(shortTokenAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.token = action.payload.token;
        state.info = action.payload;
        state.listRoles = action.payload?.listRoles;
        state.firstLogin =
          action.payload?.account?.status === 0 ||
          action.payload?.statusDescription?.status === 0
            ? true
            : false;
        state.errorCode = undefined;
      })
      .addCase(shortTokenAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.data?.errors?.[0]?.code === 100) {
          message.error("Tên tài khoản hoặc mật khẩu không đúng!");
        } else if (action?.payload?.data?.errors?.[0]?.code === 103) {
          message.error("Tài khoản không có quyền truy cập hệ thống!");
        } else {
          message.error("Đăng nhập thất bại");
        }
        window.location.href = process.env.REACT_APP_LINK_WEB;
      });
  },
});

export const getInforUser = (state) => state.auth.info;
export const getPermissionUser = (state) => state.auth.listRoles;
export const { logout, updateAuth } = authSlice.actions;

export default authSlice.reducer;
