import axios from "axios";
import { checkRoleToRedirect } from "utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_EXPORT,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.data?.errors?.[0]?.msg === "Token expired" ||
      error?.response?.data?.errors?.[0]?.msg === "Invalid token"
    ) {
      checkRoleToRedirect();
    }

    return Promise.reject(error);
  }
);

export default instance;
