import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import _ from "lodash";
import {
  getNCCJoinQuestions,
  getNCCofQuestions,
  getQuestionDetail,
  getQuestions,
  postQuestion,
  putStatusQuestion,
} from "./questionAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  presenter: undefined,
  nccList: undefined,
  nccJoinList: undefined,
};

export const getQuestionsAsync = createAsyncThunk(
  "questions",
  async (params, thunkAPI) => {
    try {
      const response = await getQuestions(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getQuestionDetailAsync = createAsyncThunk(
  "questionDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getQuestionDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postQuestionAsync = createAsyncThunk(
  "postQuestion",
  async (data, thunkAPI) => {
    try {
      const response = await postQuestion(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putStatusQuestionAsync = createAsyncThunk(
  "putStatusQuestion",
  async (data, thunkAPI) => {
    try {
      const response = await putStatusQuestion(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCofQuestionAsync = createAsyncThunk(
  "nccQuestions",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCofQuestions(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNCCJoinQuestionAsync = createAsyncThunk(
  "nccJoinQuestions",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCJoinQuestions(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearQuestionDetail: (state) => {
      state.detail = undefined;
    },
    clearQuestions: (state) => {
      state.list = undefined;
    },
    clearNCCQuestions: (state) => {
      state.nccList = undefined;
    },
    clearNCCJoinQuestions: (state) => {
      state.nccJoinList = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuestionsAsync.fulfilled, (state, action) => {
        state.list = action.payload.questions;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getQuestionsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getQuestions",
          };
        }
      })
      .addCase(getQuestionDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuestionDetailAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(action.payload.question);

        if (clone?.content?.length > 0) {
          clone.content = clone.content.map((c) => Object.values(c)?.[0] || "");
        }

        state.detail = clone;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getQuestionDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getQuestionDetail",
          };
        }
      })
      .addCase(postQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postQuestionAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(postQuestionAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thêm mới khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "addQuestion",
          };
        }
      })
      .addCase(putStatusQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putStatusQuestionAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state.detail);
        clone.status = action.payload.status;
        state.detail = clone;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putStatusQuestionAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật trạng thái khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "addQuestion",
          };
        }
      })
      .addCase(getNCCofQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCofQuestionAsync.fulfilled, (state, action) => {
        state.nccList = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getNCCofQuestionAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy danh sách Thành Viên của khóa học thất bại");
          state.error = {
            payload: action.payload,
            title: "nccQuestions",
          };
        }
      })
      .addCase(getNCCJoinQuestionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCJoinQuestionAsync.fulfilled, (state, action) => {
        state.nccJoinList = action.payload?.members;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getNCCJoinQuestionAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy danh sách Thành Viên thất bại");
          state.error = {
            payload: action.payload,
            title: "nccJoinQuestions",
          };
        }
      });
  },
});

export const selectQuestions = (state) => state.question.list;
export const selectQuestionDetail = (state) => state.question.detail;
export const selectNCCofQuestions = (state) => state.question.nccList;
export const selectNCCJoinQuestions = (state) => state.question.nccJoinList;
export const questionsStatus = (state) => state.question.status;
export const questionsError = (state) => state.question.error;

export const {
  clearQuestionDetail,
  clearQuestions,
  setIdleStatus,
  clearNCCQuestions,
} = questionSlice.actions;

export default questionSlice.reducer;
