import { TAB } from "common/User";
import _ from "lodash";
import moment from "moment";

export function differentObject(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (_.isArray(value)) {
        if (!_.isEqual(value, base[key])) {
          result[key] = value;
        }
      } else {
        if (!value && !base[key]) {
        } else {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              !(value instanceof Date) &&
              _.isObject(value) &&
              _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        }
      }
    });
  }
  return changes(object, base);
}

export function unaccent(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export const renderURL = (url, params, formatter) => {
  let paramsValue = _.compact(
    _.map(params, (p, key) => {
      if (p) {
        if (typeof p === "object") {
          if (p.id) return `${key}=${p.id}`;
          else if (_.isArray(p)) {
            return `${key}=[${p}]`;
          } else {
            let formatDate = moment(p).format(
              formatter ? formatter : "DD-MM-YYYY"
            );
            return `${key}=${formatDate}`;
          }
        } else {
          return `${key}=${p}`;
        }
      }
    })
  );
  return _.map(paramsValue, (pv, index) => (!index ? pv : "&" + pv)).join("");
};

export const checkRoleToRedirect = () => {
  const local = JSON.parse(localStorage.getItem("hblELearning"));
  if (local?.account?.idRole === TAB?.id) {
    window.location.replace(process.env.REACT_APP_LINK_WEB);
    localStorage.removeItem("hblELearning");
  } else {
    window.location.replace("/");
    localStorage.removeItem("hblELearning");
  }
};

export const handleSetTimeout = (callback, delay = 5000) => {
  if (callback && typeof callback === "function") {
    setTimeout(() => {
      callback();
    }, delay);
  } else {
    console.log("must have callback");
  }
};
