import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import nccReducer from "../features/ncc/nccSlice";
import tabReducer from "../features/tab/tabSlice";
import masterImportReducer from "../features/masterImport/masterImportSlice";
import courseReducer from "../features/course/courseSlice";
import questionReducer from "../features/question/questionSlice";
import recheckReducer from "../features/recheck/recheckSlice";
import fastRegisterReducer from "../features/fastRegister/fastRegisterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    ncc: nccReducer,
    tab: tabReducer,
    masterImport: masterImportReducer,
    course: courseReducer,
    question: questionReducer,
    recheck: recheckReducer,
    fastRegister: fastRegisterReducer,
  },
});
