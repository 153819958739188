import axiosClient from "../axiosClient";
import axiosClientDownload from "../axiosClientDownload";

const getToken = () => {
  return (
    (localStorage?.hblELearning &&
      JSON.parse(localStorage.hblELearning)?.accessToken) ||
    undefined
  );
};

const getCourses = (params) => {
  return axiosClient.get("course", {
    params,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getCourseDetail = (idCourse) => {
  return axiosClient.get("course/" + idCourse, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const postCourse = (data) => {
  return axiosClient.post("course", data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putCourse = (id, data) => {
  return axiosClient.put("course/" + id, data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const putStatusCourse = (data) => {
  return axiosClient.put("course/" + data?.id + "/status", data?.data, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCofCourses = (params) => {
  return axiosClient.get(`course/list-ncc/${params?.idCourse}`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const getNCCJoinCourses = (params) => {
  return axiosClient.get(`course/list-ncc-course/${params?.idCourse}`, {
    params: params?.values,
    headers: {
      Authorization: getToken(),
    },
  });
};

const pickNCCJoinCourses = (params) => {
  return axiosClient.post(`course/pick-course`, params, {
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportCoursesAPI = (params, options) => {
  return axiosClientDownload.get("excel/course/courses", {
    params,
    ...options,
    headers: {
      Authorization: getToken(),
    },
  });
};

const exportMemberCoursesAPI = (params, options) => {
  return axiosClientDownload.get("excel/course/members-in-course", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getCourses,
  getCourseDetail,
  exportCoursesAPI,
  postCourse,
  putCourse,
  putStatusCourse,
  getNCCofCourses,
  getNCCJoinCourses,
  pickNCCJoinCourses,
  exportMemberCoursesAPI,
};
